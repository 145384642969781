section.contact-form {

    .mw {
        max-width: 500px;
    }
    p {
        font-family: eb-garamond, serif;
    }
    ::placeholder {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.15em;
    }
    .form-control {
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        letter-spacing: 0.15em;
    }
    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 10px !important;
    }
    textarea {
        min-height: 142px;
    }
    [id*="btnSend"] {
        max-width: 196px;
    }

    @media screen and (max-width: 1800px) {
        .qc-form-wrapper {
            padding: 0;
        }
    }
    @media screen and (max-width: 1600px) {
        .qc-form-wrapper {
            padding: 0;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            padding-top: 0;
        }
    }
    @media screen and (max-width: 991px) {
        .qc-form-wrapper {
            margin: 0 auto;
            padding: 75px 0 30px;
        }
    }
    @media screen and (max-width: 767px) {
        .qc-form-wrapper {
            padding: 75px 0 30px;
        }
    }
}
