section.tagline-section {
    background-color: $primary;

    .top {
        font-family: eb-garamond, serif;
        letter-spacing: 0.02em;
        font-size: 27px;

        span {
            color: rgba($color: #FFFFFF, $alpha: 0.20);
            font-size: 26px;
        }
        br {
          display: none;
        }
        .social {
            font-size: 16px;
        }
    }
}