section.hero-section {
    min-height:400px;
    height: 1009px;

    .logo {
        margin-top: 160px;
    }
    .mod {
        min-height:400px;
        height: 1009px;

        .display-1 {
            letter-spacing: 0.15em;
        }
    }
    @media screen and (max-width: 1600px) {
        & {
            height: 1009px;
        }
        .mod {
            height: 1009px;
        }
        section.ctas {
            top: 9.5%;
        }
    }
    @media screen and (max-width: 991px) {
        & {
            height: 63vw;
        }
        .mod {
            height: 63vw;
        }
    }
}