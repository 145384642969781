.mr-2 {
margin-right:2px !important;
}

.mr-1 {
margin-right:1px !important;
}

.mb-1 {
margin-bottom:1px !important;
}

@media screen and (min-width: 576px) {.mb-sm-0 {
margin-bottom:0px !important;
}

}
 .p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

@media screen and (min-width: 1200px) {.mx-xl-1 {
margin-left:1px !important;margin-right:1px !important;
}

}
 .py-4 {
padding-top:4px !important;padding-bottom:4px !important;
}

.p-0 {
padding:0px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.m-0 {
margin:0px !important;
}

.py-5px {
padding-top:5px !important;padding-bottom:5px !important;
}

.h-100 {
height:100% !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.mb-55px {
margin-bottom:55px !important;
}

@media screen and (min-width: 992px) {.w-lg-100 {
width:100% !important;
}

}
 @media screen and (min-width: 992px) {.p-lg-0 {
padding:0px !important;
}

}
 .mb-2 {
margin-bottom:2px !important;
}

.m-0 {
margin:0px !important;
}

.p-0 {
padding:0px !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.m-0 {
margin:0px !important;
}

.p-0 {
padding:0px !important;
}

.pt-40px {
padding-top:40px !important;
}

.h-100 {
height:100% !important;
}

.py-5 {
padding-top:5px !important;padding-bottom:5px !important;
}

.mb-1 {
margin-bottom:1px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.py-5 {
padding-top:5px !important;padding-bottom:5px !important;
}

.py-5 {
padding-top:5px !important;padding-bottom:5px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

.px-5 {
padding-left:5px !important;padding-right:5px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

@media screen and (min-width: 1200px) {.mb-xl-0 {
margin-bottom:0px !important;
}

}
 .mb-4 {
margin-bottom:4px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mt-4 {
margin-top:4px !important;
}

.p-5 {
padding:5px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 @media screen and (min-width: 992px) {.p-lg-5 {
padding:5px !important;
}

}
 .mb-5 {
margin-bottom:5px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

.mt-4 {
margin-top:4px !important;
}

.mb-1 {
margin-bottom:1px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.mt-5 {
margin-top:5px !important;
}

@media screen and (min-width: 992px) {.mt-lg-0 {
margin-top:0px !important;
}

}
 .p-5 {
padding:5px !important;
}

.px-5 {
padding-left:5px !important;padding-right:5px !important;
}

.py-3 {
padding-top:3px !important;padding-bottom:3px !important;
}

.mt-0 {
margin-top:0px !important;
}

.mb-2 {
margin-bottom:2px !important;
}

.mb-2 {
margin-bottom:2px !important;
}

.px-5 {
padding-left:5px !important;padding-right:5px !important;
}

.pt-50px {
padding-top:50px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

.pb-40px {
padding-bottom:40px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 .mt-5 {
margin-top:5px !important;
}

.mb-1 {
margin-bottom:1px !important;
}

.mr-5px {
margin-right:5px !important;
}

.mb-5 {
margin-bottom:5px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 @media screen and (min-width: 992px) {.mt-lg-90px {
margin-top:90px !important;
}

}
 .mt-50px {
margin-top:50px !important;
}

.py-3 {
padding-top:3px !important;padding-bottom:3px !important;
}

.px-1 {
padding-left:1px !important;padding-right:1px !important;
}

.py-3 {
padding-top:3px !important;padding-bottom:3px !important;
}

.py-3 {
padding-top:3px !important;padding-bottom:3px !important;
}

.pr-4 {
padding-right:4px !important;
}

.pt-1 {
padding-top:1px !important;
}

.w-100 {
width:100% !important;
}

.w-100 {
width:100% !important;
}

.mb-2 {
margin-bottom:2px !important;
}

.w-50 {
width:50% !important;
}

.w-50 {
width:50% !important;
}

.p-10px {
padding:10px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.mx-2 {
margin-left:2px !important;margin-right:2px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.m-0 {
margin:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.pt-4 {
padding-top:4px !important;
}

.pr-10px {
padding-right:10px !important;
}

.p-2 {
padding:2px !important;
}

.pt-2 {
padding-top:2px !important;
}

@media screen and (min-width: 768px) {.pt-md-4 {
padding-top:4px !important;
}

}
 .pb-4 {
padding-bottom:4px !important;
}

.px-2 {
padding-left:2px !important;padding-right:2px !important;
}

.w-100 {
width:100% !important;
}

.pt-15px {
padding-top:15px !important;
}

.mb-2 {
margin-bottom:2px !important;
}

.mr-24px {
margin-right:24px !important;
}

.mr-4 {
margin-right:4px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.p-0 {
padding:0px !important;
}

