body {
    font-family: "Poppins", sans-serif !important;
  }
  body section.main {
    font-family: Poppins, sans-serif !important;
  }
  body .bg-orange {
    background-color: #ff5602 !important;
  }
  body .bg-light-orange {
    background-color: #ed8142 !important;
  }
  body .light-grey {
    background-color: #f8fafb !important;
  }
  body .light-blue {
    background-color: #f1fcff !important;
  }
  body .ultra-blue {
    background-color: #0f40b9 !important;
  }
  .btn {
    padding: 7px 14px;
    letter-spacing: 0;
    border-radius: 50px;
    padding: 18px 24px;
    font-size: 12px;
    font-weight: 700;
  }
  .btn-primary {
    color: #fff !important;
    background-color: rgb(17, 51, 102) !important;
  }
  .btn-large {
    font-size: 16.8px;
  }
  .services-section .btn,
  .serv-section .btn {
    font-size: 1em;
  }
  section.hero-section {
    height: unset;
  }
  section.hero-section .mod {
    aspect-ratio: 3/2;
    height: unset;
  }
  body section.hero-section,
  body section.hero-section .mod {
    height: unset !important;
    min-height: 1px !important;
  }


  section.hero-section .panel-image {
    background-size: cover;
    box-shadow: none;
  }
  .btn-ghost {
    border: solid 1px #136 !important;
    color: #136 !important;
    background-color: #fff !important;
    font-weight: 600 !important;
    letter-spacing: 0.1em !important;
  }
  .btn-ghost:hover {
    box-shadow: 0 0 5px 1px #136 !important;
  }
  .btn-orange {
    background-color: #ff5c2a !important;
    color: #ffffff !important;
    transition: box-shadow 0.65s cubic-bezier(0.68, -0.55, 0.265, 1.55),
      background-color 0.25s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .btn-orange:hover {
    background-color: #ff5c2a !important;
    color: #fff !important;
    box-shadow: 0 0 10px 0 #ff5c2a !important;
  }
  body #main .row img {
    height: auto !important;
    width: auto !important;
    max-width: 100% !important;
  }
  section.home-banner {
    margin-left: 150px !important;
    .text {
      max-width: none !important;
    }
    .custom-container {
      max-width: unset;
    }
  }
  #myModal .mySlides {
    text-align: center;
    img {
            max-height: 70vh;
            position: absolute;
            max-width: 80vw;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -65%);
        }
}
#myModal .thumbnails {
    overflow: scroll;
    width: 100vw;
    background: #222;
    text-align: center;
    justify-content: start;
    display: flex;
    .column {
        flex-shrink: 0;
    }
}
  .custom-modal {
    display: none;
    position: fixed;
    z-index: 10000;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
  
    .row > .column {
      padding: 0 8px;
    }
  
    .row:after {
      content: "";
      display: table;
      clear: both;
    }
  
    .column {
      float: left;
      background: #222;
      height: 164px !important;
      width: 120px !important;
      position: relative;
      overflow: hidden;
      padding: 2vh 1vh;
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
      }
    }
  
    /* Modal Content */
    .custom-modal-content {
      position: relative;
      margin: auto;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  
    /* The Close Button */
    .close {
      color: white;
      position: absolute;
      top: 10px;
      right: 25px;
      font-size: 35px;
      font-weight: bold;
    }
    .thumbnails {
      position: fixed;
      bottom: 0;
    }
    .close:hover,
    .close:focus {
      color: #999;
      text-decoration: none;
      cursor: pointer;
    }
  
    .mySlides {
      display: none;
      width: 80vw;
      margin: auto;
    }
  
    .cursor {
      cursor: pointer;
    }
  
    /* Next & previous buttons */
    .prev,
    .next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: auto;
      padding: 16px;
      margin-top: -50px;
      color: white !important;
      font-weight: bold;
      font-size: 24px;
      transition: 0.6s ease;
      border-radius: 0 3px 3px 0;
      user-select: none;
      -webkit-user-select: none;
    }
  
    /* Position the "next button" to the right */
    .next {
      right: 0;
      border-radius: 3px 0 0 3px;
    }
  
    /* On hover, add a black background color with a little bit see-through */
    .prev:hover,
    .next:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  
    /* Number text (1/3 etc) */
    .numbertext {
      color: #f2f2f2;
      font-size: 12px;
      padding: 8px 12px;
      position: absolute;
      top: 0;
    }
  
    img {
      margin-bottom: -4px;
    }
  
    .caption-container {
      text-align: center;
      background-color: black;
      padding: 2px 16px;
      color: white;
    }
  
    .demo {
      opacity: 0.6;
    }
  
    .active,
    .demo:hover {
      opacity: 1;
    }
  
    img.hover-shadow {
      transition: 0.3s;
    }
  
    .hover-shadow:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
  
  .tour-grid {
    grid-column-gap: 0.75em;
    grid-row-gap: 0.75em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    display: grid;
  }
  #w-node-_90806241-1cdc-987a-6818-d0009704a7b4-9704a7b1 {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }
  #w-node-_90806241-1cdc-987a-6818-d0009704a7b7-9704a7b1 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
  #w-node-_90806241-1cdc-987a-6818-d0009704a7ba-9704a7b1 {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }
  #w-node-_90806241-1cdc-987a-6818-d0009704a7bd-9704a7b1 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
  #w-node-_90806241-1cdc-987a-6818-d0009704a7c0-9704a7b1 {
    grid-area: span 1 / span 4 / span 1 / span 4;
  }
  #w-node-_90806241-1cdc-987a-6818-d0009704a7c3-9704a7b1,
  #w-node-_6d3aa468-53ae-db37-218b-ca815a98ec6c-c48ddf48,
  #w-node-_6485277f-0c19-27a1-08ee-a1f37b6607d0-c48ddf62,
  #w-node-_4b3859d6-9800-e079-4f94-36cb69c5a59d-c48ddf62,
  #w-node-_61d6e083-8c04-c4c9-59a7-ceb449c287b5-c48ddf66 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
  .tour-grid-box {
    width: 100%;
    min-height: 24vw;
    max-height: 24vw;
    position: relative;
    overflow: hidden;
  }
  
  .tour-grid-box-image-main.large,
  .tour-grid-box-image-secondary.large {
    min-width: 713px;
  }
  
  html.w-mod-touch * {
    background-attachment: scroll !important;
  }
  .tour-grid-box-image-main {
    object-fit: cover;
    width: 100%;
    height: 100% !important;
    position: absolute;
    inset: 0%;
  }
  .tour-grid-box-image-secondary {
    opacity: 0;
    object-fit: cover;
    width: 100%;
    height: 100% !important;
    position: absolute;
    inset: 0%;
  }
  .w-container:before,
  .w-container:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  .container-cw.container-cw-full-width {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
  .touching-boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .text {
      padding: 3em;
    }
    .image img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
    .text,
    .image {
      width: 50%;
    }
  }
  .custom-container.interior {
    padding: 125px 60px 0;
    margin-bottom: 125px;
    .full-width {
      width: calc(100vw - 20px);
      margin-left: calc(-50vw + 50% + 10px);
    }
    .full-width.banner {
      margin-top: -125px !important;
    }
  }
  .rating-badge p {
    color: rgba(0, 0, 0, 0.56);
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75em;
  }
  .rating-badge i {
    color: #f9b403;
    margin-top: 3em;
  }
  .rating-badge img {
    filter: grayscale(1);
    width: 8em;
  }
  .hero {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  h1 {
    font-size: 48px;
  }
  .panel-image {
    -webkit-box-shadow: inset 0px 0px 0px 5px #ffffff;
    -moz-box-shadow: inset 0px 0px 0px 5px #ffffff;
    box-shadow: inset 0px 0px 0px 5px #ffffff;
    background-size: 138% !important;
  }
  .hero-image-wrap {
    max-width: 900px;
    margin: 1em auto 0 0;
    max-height: 800px;
    margin-top: 25px;
  }
  .hero-image {
    padding: 0 !important;
    margin: 0 !important;
    aspect-ratio: 1977/1689;
  }
  .tagline {
    font-size: 17.6px;
  }
  .text {
    margin-right: 82px;
    margin-top: 175px;
    margin-bottom: 175px;
  }
  .buttons .button {
    width: unset !important;
    max-width: none !important;
  }
  .btn-orange {
    margin-right: 35px;
  }
  .btn {
    font-size: 16.8px;
  }
  .about-section {
    .doctor-quote {
      font-style: italic;
      line-height: 1.75;
      font-weight: 500;
    }
    .row {
      max-width: 1600px;
      margin: auto;
    }
    img {
      max-width: 62%;
      border-radius: 10%;
      margin: 50px 0 65px;
    }
    .wrapper-section {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      margin-top: 2em;
      display: grid;
    }
    .wrapper-section h6 {
      text-transform: none;
      font-family: Poppins, sans-serif;
      font-size: 1.2em;
      font-weight: 700;
      margin-bottom: 0.5em;
    }
    .dr h5,
    .dr p,
    .dr a {
      color: #546b83;
      line-height: 1;
    }
    .dr h5 {
      font-size: 1.3em;
    }
    .dr a {
      color: #546b83;
      font-weight: 600;
      transition: all 0.425s cubic-bezier(0.86, 0, 0.07, 1),
        color 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      font-size: 16px;
    }
    .dr .title {
      letter-spacing: 0.2em;
      text-transform: uppercase;
      height: 4vh;
      font-size: 0.8em;
      font-weight: 500;
    }
  
    .doctor-quote {
      font-size: 1.7em;
    }
  }
  .link {
    color: #136;
    font-weight: 600;
    transition: all 0.425s cubic-bezier(0.86, 0, 0.07, 1),
      color 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .link:hover {
    color: #1d68f6;
    font-weight: 700;
    text-decoration: underline !important;
  }
  
  .owl-carousel .owl-nav {
    [class*="owl-"]:hover {
      color: rgba(29, 104, 246, 0.85) !important;
      background-color: #fff !important;
    }
    .owl-next {
      font-size: 24px !important;
      font-weight: 400 !important;
      background-color: #fff !important;
      color: #dcdcdc !important;
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
      transition: color 0.2s, width 0.25s !important;
      margin: 0 !important;
      padding: 0 !important;
      i {
        margin: 25px !important;
        padding: 0 !important;
      }
    }
    .owl-prev {
      font-size: 24px !important;
      font-weight: 400 !important;
      margin: 25px 25px 25px 0 !important;
      background-color: #fff !important;
      color: #dcdcdc !important;
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      transition: color 0.2s, width 0.25s !important;
      margin: 0 !important;
      padding: 0 !important;
      i {
        margin: 25px !important;
        padding: 0 !important;
      }
    }
  }
  
  .reviews-section {
    background-position: center;
    background-size: cover;
    background-attachment: scroll, fixed;
    margin-bottom: 40px;
    padding-top: 40px;
    padding-bottom: 10px;
    .container-fluid {
      background-color: rgba(255, 255, 255, 0.9);
      padding-top: 104px;
      padding-bottom: 114px;
    }
    .item {
      background-color: #f8fafb;
      color: #136;
      padding: 2.5em;
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.07);
    }
    img {
      height: 40px !important;
      width: auto !important;
    }
  
    .review-name {
      margin-bottom: 0.5em;
      font-size: 1.25em;
      font-weight: 700;
    }
    .review-text {
      margin-top: 3em;
      margin-bottom: 1.5em;
      font-weight: 500;
    }
    .row {
      overflow: hidden;
    }
  }
  .custom-container .serv-section,
  .home .services-section {
    h5 {
      margin-top: 0;
      margin-bottom: 0.5em;
      font-size: 2em;
      font-weight: 700;
      line-height: 1.25;
    }
    img {
      object-position: 50% 0%;
      height: 25em !important;
      min-height: 25px;
      width: 100%;
      object-fit: cover;
    }
    .description {
      margin-bottom: 15px;
      max-width: 28em;
      font-size: 1.2em;
      font-weight: 500;
      min-height: 4em;
    }
    .special-box {
      font-size: 1em;
      position: absolute;
      bottom: 0.5em;
      left: auto;
      right: -1.5em;
      transform: rotate(352deg);
    }
    .special {
      background-color: rgba(15, 64, 185, 0.8);
      color: #fff;
      border-radius: 100%;
      width: 144px;
      height: 144px;
      padding: 0.3em;
      line-height: 1.25;
      font-size: 1em;
    }
    .special-wrapper {
      background-color: #1d68f6;
      border-radius: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      display: flex;
    }
    .special-price {
      font-size: 19.2px;
      font-weight: 800;
      line-height: 1.25;
      color: #fff;
    }
    .special-text {
      text-align: center;
      font-size: 14.4px;
      font-weight: 500;
      line-height: 1.25;
      color: #fff;
    }
    .border-container {
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 3em;
      padding-right: 3em;
    }
    .bordered-top {
      border-top: 2px solid #93d0ee;
      padding-top: 3em;
    }
  }
  .contact-section {
    .gray-box {
      background-color: #f8fafb;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 1.5em;
    }
    .title {
      font-size: 2.7em;
    }
    .days,
    .hours {
      padding: 0;
    }
    .days p {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 1em;
    }
    .hours p {
      font-size: 20px;
      margin-bottom: 1em;
    }
  }
  .cta-section {
    padding: 9%;
    .box {
      opacity: 1;
      padding: 20px 40px;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      border-radius: 10px;
      max-width: 650px;
      background-color: #f1f1f1;
    }
    .eyebrow {
      color: rgba(15, 64, 185, 0.8);
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0.75em;
      font-size: 1em;
      font-weight: 500;
      margin-top: 1.5em;
    }
    h4 {
      font-size: 2em;
      font-weight: 700;
    }
  }
  .interior .form .custom-container {
    margin: 50px auto;
}
  .custom-container {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 3em;
}
  .form .custom-container {
    margin-top: 4em;
    margin-bottom: 4em;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 3em;
    .row {
      background-color: #136;
      padding: 8em 3em;
      margin: 0;
    }
    .form-control {
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: solid 1px #fff;
    }
    .form-btn {
      background-color: #f8fafb;
      color: #1d68f6;
      transition: background-color 0.35s cubic-bezier(0.68, -0.55, 0.265, 1.55),
        box-shadow 0.22s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      border-radius: 50px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      padding: 1em 2em;
      font-size: 1.25em;
      min-width: 15em;
    }
    .form-btn:hover {
      background-color: #f8fafb;
      box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
      color: #136;
    }
    .form-control {
      color: #fff;
    }
    ::placeholder {
      font-size: 1.1em;
      font-weight: 600;
      color: #ababab;
    }
    textarea {
      overflow: hidden;
    }
  }
  .page-blog .row {
    margin: 0 !important;
  }
  .blue-top-border {
    border-top: 2px solid #93d0ee;
  }
  .care-section {
    .border-custom {
      margin-top: 3em;
      border-top: 4px solid #93d0ee;
      padding-top: 3em;
    }
    .row {
      grid-column-gap: 2em;
      grid-row-gap: 2em;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-auto-columns: 1fr;
      display: grid;
      margin: 0;
    }
    .cols {
      border-top: 2px solid #93d0ee;
      padding: 3em 0 0 0;
      margin: 3em 0;
    }
  }
  .patient-safety-section {
    margin-bottom: 3rem;
    .checklist div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .checklist p {
      font-size: 1.5em;
      font-weight: 600;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      display: inline-block;
    }
    .checklist i {
      color: #2084eb;
      margin-right: 0.5em;
      font-size: 2em;
    }
    .wrapper {
      margin: 0;
      background-color: #136;
      background-position: 0 0;
      background-size: auto;
      padding: 1.5em;
    }
    .row {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .book-online-section {
    margin-top: 0;
    padding: 4em 0;
    background-color: #f8fafb;
    .wrapper {
      max-width: 45em;
    }
    h2 {
      color: #333;
      font-size: 2.5em;
      margin: 0.5em auto;
    }
  }
  .foot {
    background-color: #1b2c5d !important;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    );
    h3 {
      color: #fff;
      border-bottom: 1px solid #93d0ee;
      margin-top: 0;
      font-size: 1.5em;
      padding-bottom: 0.5em;
      margin-bottom: 1em;
      line-height: 1.25;
    }
    a {
      color: #fff;
    }
    p {
      font-size: 1em;
      font-weight: 600;
    }
    li {
      padding: 0;
      margin: 1em 0;
    }
    li:nth-child(1) {
      margin: 0 0 1em 0;
    }
    ul {
      padding: 0;
      margin: 0;
    }
    a:hover {
      color: #93d0ee !important;
    }
  }
  
  #section-top {
    padding: 1em 16px !important;
    background-image: url("/prositesStyles/StyleLibrary/6733/images/top-bar-hover-bg.jpg");
    background-position: 0%;
    background-size: 4000px 1000px;
    transition: background-position 0.8s cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    &:hover {
      background-position: 100%;
    }
    p,
    div {
      display: inline-block;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 600;
      letter-spacing: 0.12em;
      margin-bottom: 0;
      line-height: 1.75;
    }
    img {
      display: inline-block !important;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .america,
    .mexico {
      margin-right: 5px;
    }
    .top-flex-div {
      margin-right: 7px;
    }
    i {
      color: #fff;
      opacity: 0.5;
      margin-left: 5px;
    }
    a:hover {
      background-image: url("/prositesStyles/StyleLibrary/6733/images/top-bar-hover-bg.jpg");
      background-position: 0%;
      background-size: 4000px 1000px;
      transition: background-position 0.8s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    i {
      color: #fff;
      opacity: 0.5;
    }
  }
  #section-top {
    padding: 0.3em 16px !important;
  }
  
  .hero-image-wrap {
    aspect-ratio: 1977/1689;
  }
  .slideshow-section .panel-image {
    background-size: cover !important;
  }
  .accordion-mod {
    background-color: #1d68f6;
    padding-top: 4em;
    padding-bottom: 4em;
    margin-bottom: 4em;
    color: #ffffff;
  }
  .accordion-mod .container {
    border-top: 0.25em solid #93d0ee;
  }
  .accordion-mod .accordion {
    background-color: #1d68f6;
    color: #ffffff;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: 0;
    transition: 0.4s;
  }
  .accordion-mod .card-body {
    line-height: 1.75;
  }
  .accordion-mod .btn {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 700;
    padding: 0;
    line-height: 78px;
    text-decoration: none;
    font-size: 1.25rem;
  }
  .accordion-mod .h1 {
    font-size: 2em;
    line-height: 1.25;
    padding-top: 50px;
  }
  .accordion-mod i {
    font-size: 14px;
    margin-left: 5px;
  }
  
  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background-color: #eee;
    color: #444;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
  }
  .panel {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
  }
  .header-section {
    .sticky-wrapper {
      padding: 0 100px !important;
      box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
    }
    .sticky-nav {
      .btn {
        letter-spacing: 0.1em !important;
      }
      .btn-ghost {
        margin-right: 20px;
      }
    }
    .custom-container {
      max-width: none;
    }
    .row {
      margin: 0;
    }
    .nav-link {
      margin: 0 12px !important;
    }
    .padding-right {
      padding-right: 0 !important;
    }
    .hamburger i {
      font-size: 36px;
    }
  }
  .hamburger p {
    letter-spacing: 0;
    font-size: 0.6em;
    font-weight: 800 !important;
    margin: 0;
  }
  .hamburger.mobile i {
    font-size: 41px;
  }
  .content-section {
    background-color: #f8fafb;
    padding: 104px 2em;
    h1 {
      font-weight: 700;
      font-size: 2.6875rem;
    }
    p {
      font-family: "Poppins", sans-serif;
    }
  }
  .outro-section {
    margin-top: 4em;
    background-position: center;
    background-size: cover;
    background-attachment: scroll, fixed;
    .wrapper {
      padding: 104px 2em;
      background-color: rgba(255, 255, 255, 0.9);
    }
    .btn-ghost {
      margin-right: 2em;
    }
  }
  #navbar-mobile ul li a {
    color: #ffffff !important;
    text-transform: capitalize;
  }
  .popup {
    position: fixed;
    bottom: 0;
    width: 100%;
    .hamburger {
      position: absolute;
      top: 0;
      right: 0;
      i {
        font-size: 30px;
      }
    }
    .popup-buttons {
      a {
        color: #ffffff;
        font-size: 1em;
        font-weight: 700;
        text-transform: uppercase;
        padding: 1.5em 0.7em;
      }
    }
    .navy-popup {
      max-width: 1000px;
      margin: auto;
      .tidal {
        color: #f1f1f1;
        letter-spacing: 0.3em;
        font-size: 46px;
        text-transform: uppercase;
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        line-height: 1.2;
        margin: 0;
      }
      .aligners {
        color: #6ad7dd;
        text-transform: uppercase;
        font-family: Montserrat, sans-serif;
        font-size: 2.4em;
        font-weight: 600;
        line-height: 1.2;
        margin: 0;
      }
      .divider {
        background-color: #7ac8ec;
        width: 1px !important;
        height: 112px;
        margin-left: 2em !important;
        margin-right: 2em !important;
        padding: 0 !important;
        margin: 0;
      }
      .starting-at {
        color: #87bfe0;
        text-transform: none;
        font-family: Montserrat, sans-serif;
        font-size: 30.5px;
        font-weight: 400;
        line-height: 1.3;
        margin: 0;
      }
      .price {
        color: #f8fafb;
        font-size: 54.75px;
        font-weight: 700;
        margin: 0;
        line-height: 1.2;
      }
      .img {
        max-height: 143px;
        max-width: 260px;
        object-fit: cover;
        object-position: top center;
        padding-top: 5px;
      }
      .button-group {
        width: 200px;
        height: 112px;
        .btn-orange,
        .btn-ghost {
          font-size: 12.8px;
          padding: 1em 2em !important;
        }
        .btn-orange {
          margin-bottom: 12px;
        }
      }
    }
  }
  
  .touching-boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .text {
      padding: 3em;
    }
    .image img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
    .text,
    .image {
      width: 50%;
    }
  }
  
  .border-bottom {
    border-bottom: 2px solid #7ac8ec !important;
  }
  
  .bordered-bottom {
    border-bottom: 2px solid #7ac8ec !important;
  }
  
  .bordered-top.bordered-bold {
    border-top-width: 0.25em;
    border-top-color: #93d0ee;
  }
  
  .bordered-top .bordered-top-short {
    padding-top: 1.5em;
    border-top: 1px solid #93d0ee;
  }
  
  .rating-badge-stars {
    color: #fff;
  }
  
  .hero-tagline {
    width: 100%;
    margin-bottom: 0;
    font-size: 1.2em;
  }
  
  .btn-white-hero {
    background-color: #fff !important;
    color: #1d68f6 !important;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: 1px solid #fff;
    border-radius: 4em;
    padding: 1em 2em;
    font-weight: 600;
    text-decoration: none;
  }
  
  .button-phone-text {
    opacity: 0.8;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
  }
  
  .spacer-1 {
    padding-top: 1em;
    padding-left: 1em;
  }
  
  .service-hero-content {
    max-width: 35em;
    margin-top: 3em;
    margin-bottom: 3em;
    padding-left: 1em;
    padding-right: 2em;
  }
  
  .rating-badge-image.rating-badge-image-white {
    filter: grayscale($gray-100);
    vertical-align: middle;
    max-width: 100%;
    display: inline-block;
    color: #ffffff80;
  }
  .rating-badge-image {
    filter: grayscale($gray-100);
    width: 8em;
  }
  
  .rating-badge-text {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  .insurance-section {
    margin-top: 0;
    padding: 4em 0;
  }
  
  .insurance-section .wrapper {
    max-width: 45em;
  }
  
  .btn-outline-success:hover {
    color: #28a745;
    background-color: initial;
    border-color: #28a745 !important;
  }
  
  .btn-orange,
  .btn-orange:hover {
    background-color: #ff5c2a;
    color: #fff;
  }
  
  /* CareCredit Section */
  .cc-columns {
    height: 190px;
  }
  
  .cc-middle {
    border-right: 1px solid #93d0ee;
    border-left: 1px solid #93d0ee;
  }
  
  /* CareCredit Section */
  
  .button-cta.button-cta-short.button-edit-00 {
    background-color: #1d68f6;
    margin-top: 40px;
    margin-right: 40px;
  }
  
  .button-cta.button-cta-short {
    padding: 0.8em 1.2em;
  }
  
  .button-cta {
    color: #fff;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-shadow: none;
    border: 2px #0000;
    border-radius: 3em;
    font-weight: 700;
    text-decoration: none;
    transition-property: none;
    display: inline-block;
  }
  
  .button-cta.button-cta-short.button-edit-00.button-ghost:hover {
    background-color: #0f40b9cc;
    color: #ffffff;
  }
  
  .button-cta.button-cta-short.button-edit-00.button-ghost {
    border: 1.8px solid #0f40b9cc;
    border-radius: 4.4em;
    margin-right: 0;
    font-weight: 700;
  }
  
  .page-title {
    margin-bottom: 0.75em;
    font-size: 5em;
    font-weight: 700;
    line-height: 1.125;
  }
  
  .page-title.page-title-medium {
    font-size: 4em;
  }
  
  .page-title-small {
    margin-top: 0.4em;
    font-size: 0.65em;
    font-weight: 500;
    display: block;
  }
  
  .pre-title {
    color: #2084eb;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0.75em;
    font-size: 1em;
    font-weight: 600;
  }
  
  .section-subtitle {
    font-size: 1.4em;
  }
  
  .section-blurb {
    font-size: 1.125em;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .content-special-title {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 1em;
  }
  
  .content-special-notes {
    opacity: 0.5;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 1em;
    font-size: 0.8em;
    font-weight: 500;
  }
  
  .content-special-price {
    font-size: 3.5em;
    font-weight: 700;
    line-height: 1.125;
  }
  
  .content-section-tagline {
    text-indent: 0;
    font-size: 1em;
    font-weight: 500;
    display: inline-block;
  }
  
  .content-section-tagline .content-section-tagline-xlarge {
    font-size: 2em;
    line-height: 1.25;
  }
  .content-section-tagline {
    text-indent: 0;
    font-size: 1em;
    font-weight: 500;
    display: inline-block;
  }
  
  .content-section-tagline .content-section-tagline-narrow {
    max-width: 75%;
  }
  
  .content-section-tagline
    .content-section-tagline-large
    .content-section-tagline-narrow {
    max-width: 100%;
  }
  
  .content-section-tagline .content-section-tagline-large {
    font-size: 1.5em;
    line-height: 1.5;
  }
  
  .other-services-banner {
    object-fit: cover;
    object-position: 50% 30%;
    width: 100%;
    height: 500px !important;
  }
  
  .other-services-banner .top {
    object-position: 50% 0%;
  }
  
  .page-Location {
    .pre-title {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0.75em;
      font-size: 1em;
      font-weight: 500;
    }
  
    h2.header-text {
      font-size: 2.7em;
    }
    .office-hours,
    .directions,
    #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl00_divOfficeName {
      border-top: 2px solid #93d0ee;
      padding: 3em 0 0;
      margin: 3em 0 0;
    }
    .address,
    .numbers {
      padding: 0;
    }
    .office-hours,
    .directions,
    #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl00_divOfficeName,
    .address,
    .numbers {
      flex: 0 0 33.33333%;
      max-width: 31.8%;
      position: absolute;
      width: 100%;
    }
    #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl00_divOfficeName {
      top: 0;
      left: 0;
    }
    #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl00_divOfficeName .h4 {
      margin-bottom: 1.5625rem;
      font-family: Poppins, sans-serif;
      line-height: 1.2;
      color: #136;
      text-transform: capitalize;
      font-style: normal;
      letter-spacing: 0;
      font-size: 1.4375rem;
      font-weight: 700;
    }
    .address {
      top: 154px;
      left: 0;
    }
    .numbers {
      top: 219px;
      left: 0;
    }
    .col-lg > .btn {
      position: absolute;
      top: 269px;
      left: 0;
    }
    .office-hours {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
    #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl00_divOfficeName, .address, .numbers, .page-Location .col-lg > .btn {
      padding-left: 16px;
    }
    .ctas {
      padding-top: 415px;
    }
    hr {
      display: none;
    }
    .directions {
      top: 0;
      right: 0;
      padding-right: 16px;
    }
  }
  
@media screen and (max-width: 1920px) {
  .tour-grid-box-image-main.large {
      min-width: 533px;
  }
  .doctor-quote {
      font-size: 1.6em;
  }
  .special-box,
  .special {
      font-size: 0.875em;
  }
  .custom-container {
      padding: 0 60px !important;
  }
  .custom-container.interior {
    padding: 125px 60px 0 !important;
  }
  .home-banner .custom-container {
      margin-left: 0 !important;
      margin-right: 0 !important;
  }
  .header-section {
      .sticky {
      .sticky-wrapper {
          padding: 0 60px !important;
      }
      .sticky-nav {
          padding: 0 10px !important;
      }
      }
      .container-fluid,
      .content-section {
      padding-left: 0 !important;
      padding-right: 0 !important;
      }
  }
  section.home-banner {
      .hero-image-wrap {
      max-width: 700px;
      max-height: 750px;
      }
      .hero-image-wrap {
      margin-top: 80px;
      margin-bottom: 75px;
      }
      .hero-image {
      max-height: 725px !important;
      }
      .text {
      max-width: 544px;
      }
      .panel-image {
      background-size: 138% !important;
      }
      .btn {
      font-size: 13.2px;
      }
  }
  .services-section,
  .serv-section {
      .special-price {
      font-size: 16.8px;
      }
      .special-text {
      font-size: 12.6px;
      }
      .special {
      width: 126px;
      height: 126px;
      }
  }
  }
@media screen and (max-width: 1440px) {
    .tour-grid-box-image-main.large {
        min-width: 445px;
    }
    .tour-grid-box-image-main.small {
        min-width: 225px;
    }
    section.home-banner {
        overflow: hidden;
        margin: 0 !important;
        .hero {
        padding-right: 0 !important;
        margin-right: 75px !important;
        align-items: center !important;
        }
        .hero-image-wrap {
        max-width: 600px;
        }
        .buttons .button {
        width: unset !important;
        max-width: none !important;
        }
        .text {
        margin: 180px 1em 3em 0 !important;
        }
    }
    .doctor-quote {
        font-size: 1.4em;
    }
    .services-section img,
    .serv-section img {
        min-height: 1px !important;
        height: auto !important;
    }
    #section-top {
        padding: 0 16px;
        p,
        span {
        font-size: 12px;
        line-height: 1.5;
        }
        div {
        font-size: 14.4px;
        }
    }
    .home-banner .hero {
        margin-left: 0;
        margin-right: 0;
    }
    .header-section .sticky-nav {
        padding: 0 !important;
    }
    .header-section {
        .nav-link,
        .btn-ghost,
        .btn-orange {
        font-size: 0.75em !important;
        padding: 9px 13.5px;
        }
    }
    .content-special-price {
        font-size: 4.5em;
    }
    }
@media screen and (max-width: 1280px) {
    .tour-grid-box-image-main.large {
        min-width: 425px;
    }
    .tour-grid-box-image-main.small {
        min-width: 352px;
    }
    .tour-grid-box-image-main.small {
        min-width: 225px;
    }
    .specialty-grid-description {
        min-height: 0;
    }
    .custom-container {
      padding: 60px 50px 0 !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
      margin-bottom: 60px;
    }
    .button-cta {
        transition: background-color 50ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    body .section-text {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
    .content-special-price {
        font-size: 4rem;
    }
    }
@media screen and (max-width: 1200px) {
    #section-top div {
        font-size: 11.2px;
    }
    section.home-banner {
        .hero {
        margin-right: 25px !important;
        .text .btn-orange {
            margin-right: 15px;
        }
        }
    }
    .mobile-width {
        width: 100% !important;
    }
    .h1,
    h1 {
        font-size: calc(1.425rem + 2.1vw) !important;
    }
    }           
@media screen and (max-width: 991px) {
  #myModal .thumbnails {
    display: none;
  }
  #myModal .mySlides {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }  
  .accordion, .accordion-mod .accordion {
    padding: 18px 0 0;
  }
  .accordion-mod .btn {
    line-height: 1.4;
    font-size: 1.15rem;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    justify-content: space-between;
  }
  .tour-grid-box-image-main.large, .tour-grid-box-image-secondary.large {
        min-width: 1px;
    }
    .about-section .dr {
      padding: 0 !important;
    }
    .touching-boxes {
        display: block;
        .text,
        .image {
        width: unset !important;
        }
    }
    .care-section {
        .row {
        grid-template-columns: 1fr;
        }
        .cols {
        margin-bottom: 0;
        margin-top: 1em;
        padding-top: 2em;
        }
    }
    .top-section {
        #section-top {
        padding: 10px;
        text-align: left;
        p {
            font-size: 10px;
            line-height: 1.5;
        }
        img {
            width: 20px !important;
        }
        }
    }
    section.home-banner {
        .hero {
        padding-top: 42px !important;
        padding-right: 60px !important;
        margin-right: 0 !important;
        }
        .hero-image-wrap {
        margin: 3em 0 !important;
        max-width: 100% !important;
        }
        .hero-image {
        max-width: 100% !important;
          img {
            width: 100%;
          }
        }
        .text {
        margin: 0 !important;
        max-width: none !important;
        }
        .btn-orange {
        margin-right: 0 !important;
        }
    }
    #section-top {
        img {
        width: 15px;
        }
        p,
        i,
        div {
        font-size: 10px;
        }
    }
    .content-special-price {
        font-size: 3.5rem;
    }
    section.home-banner {
        margin-left: 0 !important;
        .hero-image-wrap {
        aspect-ratio: unset !important;
        }
        .hero-image {
        background-image: none !important;
        aspect-ratio: unset !important;
        }
    }
    .custom-container {
      padding: 60px 60px 0 !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
      margin-bottom: 60px;
  }
    .custom-container.interior {
        padding: 60px 60px 0 !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-bottom: 60px;
  
        .full-width.banner {
        margin-top: -60px !important;
        }
    }
    .text {
        margin: 0 !important;
    }
    .form .custom-container .form-btn {
        padding: 1em 1.5em;
        font-size: 1em;
        min-width: 1px;
    }
    .book-online-section {
        h2 {
        font-size: 1.75em;
        }
    }
    .reviews-section {
        .container-fluid {
        padding: 0;
        }
        .custom-container {
          padding-top: 0;
        }
    }
    .outro-section .wrapper {
        padding: 50px;
        .custom-container {
          padding-top: 0 !important;
          margin-bottom: 0 !important;
        }
    }
    .home section .custom-container {
      padding-top: 60px !important;
      padding-bottom: 0 !important;
  }
  .content-section {
      padding: 0 0 30px !important;
      margin-bottom: 0 !important;
      .custom-container {
          margin-bottom: 0;
          padding-bottom: 30px;
      }
  }
  .contact-section {
      padding: 0 60px !important;
  }
  .care-section {
      padding-top: 0px !important;
      margin-bottom: 0px !important;
  }
  .patient-safety-section {
      padding-top: 0 !important;
  }
    .page-Location #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl00_divOfficeName,
    .page-Location .address,
    .page-Location .numbers,
    .page-Location .directions,
    .page-Location .office-hours,
    .page-Location .col-lg > .btn {
        flex: none;
        display: block;
        max-width: unset;
        position: relative;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        top: unset;
        left: unset;
        transform: none;
    }
    .page-Location .col-lg > .btn {
        width: unset;
        padding: 18px 24px;
    }
    .page-Location .ctas {
        padding: 3em;
    }
    .page-intro img {
        margin-top: 3em;
    }
    }
@media screen and (max-width: 768px) {
    .home-banner .hero {
        margin: 2em 0;
    }
    .home-banner {
        margin-left: 0 !important;
    }
    .about-section .wrapper-section {
        display: block;
    }
    .patient-safety-section {
        padding: 0;
        .checklist {
        padding-top: 0 !important;
        }
    }
    .outro-section {
        .custom-container {
        padding: 0 !important;
        }
    }
    .btn-orange,
    .btn-ghost {
        margin-right: 0 !important;
        font-size: 14px;
    }
    .owl-stage-outer {
        padding: 0 1em;
    }
    .header-section .sticky .sticky-nav {
        padding: 0 !important;
    }
    .navy-popup {
        padding: 1em 0;
        .tidal,
        .aligners,
        .starting-at,
        .price {
        font-size: 175% !important;
        }
        .button-group {
        width: unset !important;
        }
    }
    .services-section,
    .serv-section {
        .special-price {
        font-size: 14.7px;
        }
        .special-text {
        font-size: 11px;
        }
        .special {
        width: 110.25px;
        height: 110.25px;
        }
    }
    #section-top {
        p,
        i,
        div {
        font-size: 5.88px;
        }
    }
    .cc-columns {
        height: 115px !important;
    }
    
    .cc-middle {
        border-right: none;
        border-left: none;
    }
    }
@media screen and (max-width: 767px) {
    .content-special-price {
        font-size: 5.4rem;
    }
    }        
@media screen and (max-width: 552px) {
    .btn {
        padding: 10px 14px;
    }
    }
@media screen and (max-width: 518px) {
.contact-section {
    .days p,
    .hours p {
    font-size: 14px;
    }
}
.form {
    .form-btn {
    font-size: 14px;
    padding: 15px 30px;
    min-width: none;
    }
}
.home .services-section .border-container {
  padding: 0 16px !important;
  .bordered-top {
    padding: 0 !important;
  }
}
.custom-container {
padding: 60px 16px 0 !important;
}
.custom-container.interior {
  padding: 60px 16px 0 !important;
}
.contact-section {
padding: 0 16px !important;
}
.custom-container,
.header-section .sticky .sticky-wrapper {
    padding: 0 16px !important;
}
section.home-banner {
    .hero {
    padding-right: 16px !important;
    }
}
#section-top {
    img {
    width: 15px;
    height: auto !important;
    }
    p,
    i,
    div {
    font-size: 8px;
    }
}
}
@media screen and (max-width: 479px) {
    #section-top {
        p,
        i,
        div {
        font-size: 11.25px;
        }
    }
    .content-special-price {
        font-size: 4em;
    }
    }





