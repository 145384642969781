
section.services-section {
    .h5 {
        color: #26282B;
        font-weight: 700;
    }
    a:hover {
        .h5 {
            color: $primary;
            -webkit-transition: all .35s;
            -moz-transition: all .35s;
            transition: all .35s;
        }
    }
}